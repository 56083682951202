import {createRoot} from "react-dom/client";
import KcApp, {defaultKcProps, getKcContext} from "keycloakify";


import "./index.css";

const params: any = {"mockPageId": "login.ftl"};

const {kcContext} = getKcContext(params);


if (kcContext === undefined) {
    throw new Error(
        "This app is a Keycloak theme" +
        "It isn't meant to be deployed outside of Keycloak"
    );
}

createRoot(document.getElementById("root")!).render(
    <KcApp
        kcContext={kcContext}
        {...defaultKcProps}
        kcHeaderWrapperClass="cs-wrapper"
        kcLogoLink='../img/keycloak-logo.png'
        kcButtonBlockClass={'cs-button-block'}
        kcHtmlClass={'cs-html'}


    />
);
